<template>
  <div>
    <div class="mobileNav">Payment</div>
    <b-container>
      <h3 class="m-0 p-4">MAKE A PAYMENT</h3>
      <div class="p-4 bg-white mycard">
        <div class="m-4">
          <div class="clientsTypeStyle">
            <p
              v-show="allPostpaidMembersData.length !== 0"
              @click="navType = 'postpaid'"
              class="shadow px-4 py-2 m-3 mycard buttonStyle"
              :class="getNavTypeStyle('postpaid')"
            >
              Monthly
            </p>
            <p
              v-show="allPrepaidMembersDataCAD.length !== 0"
              @click="navType = 'prepaidCAD'"
              class="shadow px-4 py-2 m-3 mycard buttonStyle"
              :class="getNavTypeStyle('prepaidCAD')"
            >
              Prepaid CAD
            </p>
            <p
              v-show="allPrepaidMembersDataUSD.length !== 0"
              @click="navType = 'prepaidUSD'"
              class="shadow px-4 py-2 m-3 mycard buttonStyle"
              :class="getNavTypeStyle('prepaidUSD')"
            >
              Prepaid USD
            </p>
          </div>
        </div>
        <b-row>
          <b-col md="6" sm="12" class="p-4">
            <b-row>
              <b-col sm="12" md="8" class="m-auto">
                <div class="bg-blue text-white p-3 mycard">
                  <!-- wording  -->
                  <div v-show="navType === 'postpaid'">
                    <h4 class="m-2">Outstanding Balance</h4>
                  </div>
                  <div v-show="navType === 'prepaidCAD'">
                    <h4 class="m-2">All CAD Extensions</h4>
                  </div>
                  <div v-show="navType === 'prepaidUSD'">
                    <h4 class="m-2">All USD Extensions</h4>
                  </div>
                  <!-- total amount  -->
                  <div v-show="navType === 'postpaid'">
                    <h4 class="m-2">
                      $ {{ payAllPostpaidBalancesAmount }} CAD
                    </h4>
                  </div>
                  <div v-show="navType === 'prepaidCAD'">
                    <h4 class="m-2">
                      $ {{ payAllPrepaidBalancesAmountCAD }} CAD
                    </h4>
                  </div>
                  <div v-show="navType === 'prepaidUSD'">
                    <h4 class="m-2">
                      $ {{ payAllPrepaidBalancesAmountUSD }} USD
                    </h4>
                  </div>
                </div>
              </b-col>
            </b-row>

            <div v-show="navType === 'postpaid'">
              <h4 class="mt-4 text-center">Select What You Want to Pay:</h4>
            </div>
            <div v-show="navType === 'prepaidCAD'">
              <h4 class="mt-4 text-center">Term Extension:</h4>
            </div>
            <div v-show="navType === 'prepaidUSD'">
              <h4 class="mt-4 text-center">Term Extension:</h4>
            </div>

            <!-- all postpaid balances  -->
            <div v-if="navType === 'postpaid'">
              <b-row
                class="p-3"
                v-for="el in allPostpaidMembersData"
                :key="el.name"
              >
                <b-col
                  md="8"
                  sm="12"
                  class="shadow p-2 mycard m-auto"
                  style="width :100%;"
                  :style="el.balance > 0 ? null : 'background-color: #F6F6F6'"
                >
                  <div
                    class="d-flex flex-row justify-content-between align-items-center p-3"
                  >
                    <h5 class="m-0">
                      <span>
                        {{ el.name }}
                      </span>
                    </h5>
                    <h5
                      class="m-0 text-main"
                      :style="
                        el.balance > 0 ? 'color: #59CDD8' : 'color: black'
                      "
                    >
                      $ {{ el.balance.toFixed(2) }}
                    </h5>
                  </div>
                  <div
                    v-if="el.balance > 0"
                    class="p-3"
                    style="margin-top: -20px"
                  >
                    <p class="mb-2">or choose the amount:</p>
                    <b-input
                      v-model="el.diyBalance"
                      class="mycard shadow"
                      style="width:70%; border: 0px;"
                    ></b-input>
                  </div>
                  <div v-else class="p-3" style="margin-top: -20px">
                    <p>You do not have outstanding balance.</p>
                    <p>Thank you!</p>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- all prepaid info  -->
            <div v-else>
              <b-row
                class="p-3"
                v-for="el in navType === 'prepaidCAD'
                  ? allPrepaidMembersDataCAD
                  : allPrepaidMembersDataUSD"
                :key="el.name"
              >
                <b-col
                  md="8"
                  sm="12"
                  class="shadow p-2 mycard m-auto"
                  style="width :100%;"
                >
                  <div
                    class="d-flex flex-row justify-content-between align-items-center p-3"
                  >
                    <h5 class="m-0">
                      <span>
                        {{ el.name }}
                      </span>
                    </h5>
                    <div class="m-0">
                      <el-checkbox v-model="el.isChecked">
                        <span style="font-size:18px;">Extend</span>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="px-3">
                    <p>Expiration Date: {{ el.edate }}</p>
                    <p class="text-main w-500">
                      New Expiration Date: {{ el.newEndDate }}
                    </p>
                    <div>
                      <p>Plan Fee: ${{ roundTheNumber(el.pfee) }}</p>
                      <p v-show="el.gst !== 0">
                        HST/GST ({{ (el.gst * 100).toFixed(0) + "%" }}): ${{
                          roundTheNumber(el.pfee * el.gst)
                        }}
                      </p>
                      <p v-show="el.pst !== 0">
                        PST/QST ({{
                          parseInt(el.pst * 100) === 9
                            ? (el.pst * 100).toFixed(3) + "%"
                            : (el.pst * 100).toFixed(0) + "%"
                        }}): ${{ roundTheNumber(el.pfee * el.pst) }}
                      </p>
                      <p v-show="el.svcFee !== 0">
                        Service Fee: ${{ roundTheNumber(el.svcFee) }}
                      </p>
                      <p v-show="el.currbal !== 0">
                        Current Balance: ${{ roundTheNumber(el.currbal) }}
                      </p>
                    </div>
                    <p class="w-500 text-main my-2" style="font-size:18px;">
                      Total: ${{ el.totalAmount }} {{ el.currency }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col md="6" sm="12" class="p-4">
            <b-row>
              <b-col sm="12" md="8" class="m-auto">
                <div class="bg-main text-center text-main p-3 mycard">
                  <h4 class="m-2">Total:</h4>

                  <div v-show="navType === 'postpaid'">
                    <h2 class="m-2">$ {{ getTheTotalPostpaidBalance }} CAD</h2>
                  </div>
                  <div v-show="navType === 'prepaidCAD'">
                    <h2 class="m-2">
                      $ {{ getTheTotalPrepaidBalanceCAD }} CAD
                    </h2>
                  </div>
                  <div v-show="navType === 'prepaidUSD'">
                    <h2 class="m-2">
                      $ {{ getTheTotalPrepaidBalanceUSD }} USD
                    </h2>
                  </div>
                </div>
              </b-col>
            </b-row>
            <p class="py-2 mt-2 mb-0 text-center">
              Please choose your payment method:
            </p>
            <!-- 4 payment methods  -->
            <div>
              <b-row class="p-2">
                <b-col md="8" sm="12" class="shadow mycard m-auto">
                  <div
                    class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                    @click="handlePayment('creditcard')"
                  >
                    <i class="fas fa-credit-card"></i>
                    <p class="m-0">Credit Card</p>
                  </div>
                </b-col>
              </b-row>
              <b-row class="p-2">
                <b-col md="8" sm="12" class="shadow mycard m-auto">
                  <div
                    class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                    @click="handlePayment('paypal')"
                  >
                    <i class="fab fa-paypal"></i>
                    <p class="m-0">PayPal</p>
                  </div>
                </b-col>
              </b-row>
              <b-row 
                class="p-2" 
                v-show="!isPrepaidUSD"
              >
                <b-col md="8" sm="12" class="shadow mycard m-auto">
                  <div
                    class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                    @click="handlePayment('alipay_qr')"
                  >
                    <i class="fab fa-alipay"></i>
                    <p class="m-0">AliPay</p>
                  </div>
                </b-col>
              </b-row>
              <b-row 
                class="p-2" 
                v-show="showWeChatPayConditions"
              >
                <b-col md="8" sm="12" class="shadow mycard m-auto">
                  <div
                    class="p-3 mycard d-flex flex-row justify-content-around align-items-center bg-white creditCard"
                    @click="handlePayment('wx_native')"
                  >
                    <i class="fab fa-weixin"></i>
                    <p class="m-0">WeChat Pay</p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <!-- payment static info  -->
        <b-row>
          <b-col md="6" sm="12" class="p-4">
            <p class="w-500">Billing:</p>
            <p>
              Our Billing (Monthly) Cycle is from every 2nd of the month to the
              1st of the following month & our Payment period is every 15th to
              25th. This means that you receive your invoice reminder on the
              15th of every month and you must pay before the 25th.
            </p>
            <p>
              All Overages for you current month will be on the Bill after your
              next one. There is essentially a 1 month "lag" or "delay" for
              overages. There is a charge of 5% added onto your account balance
              if you fail to pay before the 25th.
            </p>
          </b-col>
          <b-col md="6" sm="12" class="p-4">
            <p class="w-500">Payment Options:</p>
            <p>At PhoneBox there are a few ways you can make a payment:</p>
            <ul>
              <li>
                At any one of our PhoneBox locations where we accept cash,
                cheques, debit cards, and credit cards.
              </li>
              <li>
                Pay at your bank (TD, RBC, KEB, BMO, CIBC, SCOTIABANK or CREDIT
                UNIONS only). You can even pay your PhoneBox bill on your banks
                website.
              </li>
              <li>
                Pay here on our website by logging into your Online PhoneBox
                Account with your credit card.
              </li>
              <li>
                Setup automatic withdrawals from your bank account. Please
                contact Customer Care Team to receive PAD form and send it out
                to us via email.
              </li>
            </ul>
            <p class="w-500">
              We accept almost every credit card including international credit
              cards.
            </p>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <el-dialog
      :visible.sync="dialogVisible"
      :width="isMobileOrTablet ? '90%' : '30%'"
    >
      <div class="text-center">
        <i
          style="font-size: 60px"
          :style="isAlipay ? 'color:#31A3EA' : 'color:#289B37'"
          class="fab fa-alipay"
          :class="isAlipay ? 'fa-alipay' : 'fa-weixin'"
        ></i>
        <div v-show="navType === 'postpaid'">
          <h2 class="m-2">$ {{ getTheTotalPostpaidBalance }}</h2>
        </div>
        <div v-show="navType === 'prepaidCAD'">
          <h2 class="m-2">$ {{ getTheTotalPrepaidBalanceCAD }} CAD</h2>
        </div>
        <div v-show="navType === 'prepaidUSD'">
          <h2 class="m-2">$ {{ getTheTotalPrepaidBalanceUSD }} USD</h2>
        </div>

        <p class="my-2">
          Please scan the QR code to finish payment using your
          {{ isAlipay ? "Alipay" : "WeChat" }} App
        </p>
        <div>
          <b-img :src="qrCodeUrl"></b-img>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Payment",
  data() {
    return {
      navType: "",
      dialogVisible: false,
      chinesePaymentMethod: "",
      isMobileOrTablet: false,
      gintTimerHandle: 0,
      gstrPayId: "",
      qrCodeUrl: "",
      payAllPostpaidBalancesAmount: 0,
      payAllPrepaidBalancesAmountCAD: 0,
      payAllPrepaidBalancesAmountUSD: 0,
      allPostpaidMembersData: [],
      allPrepaidMembersDataCAD: [],
      allPrepaidMembersDataUSD: [],
    };
  },
  computed: {
    showWeChatPayConditions() {
      if (this.isMobileOrTablet) {
        return false;
      } else if (this.isCAD) {
        return true;
      } else {
        return false;
      }
    },
    isCAD() {
      return this.navType === 'prepaidCAD' || this.navType === 'postpaid';
    },
    isPrepaidUSD() {
      return this.navType === 'prepaidUSD';
    },
    isAlipay() {
      return this.chinesePaymentMethod[0] === "a";
    },
    accountOwnerNumber() {
      return this.$store.state.login.cnumAcctOwnerSaved;
    },
    getTheTotalPostpaidBalance() {
      let sum = 0;
      this.allPostpaidMembersData.forEach((el) => {
        if (Number(el.diyBalance) >= 0.01) {
          sum += Number(el.diyBalance);
        }
      });
      return sum.toFixed(2);
    },
    getTheTotalPrepaidBalanceCAD() {
      let sum = 0;
      this.allPrepaidMembersDataCAD.forEach((el) => {
        if (el.isChecked) {
          sum += Number(el.totalAmount);
        }
      });
      return sum.toFixed(2);
    },
    getTheTotalPrepaidBalanceUSD() {
      let sum = 0;
      this.allPrepaidMembersDataUSD.forEach((el) => {
        if (el.isChecked) {
          sum += Number(el.totalAmount);
        }
      });
      return sum.toFixed(2);
    },
  },
  watch: {
    dialogVisible(value) {
      if (!value) {
        clearInterval(this.gintTimerHandle);
      }
    },
  },
  methods: {
    getNavTypeStyle(value) {
      return this.navType === value
        ? "bg-blue text-white"
        : "bg-white text-dark";
    },
    processApiUrl(str) {
      let newStr = "";
      for (let chr of str) {
        let newChr = chr;
        if (newChr === ":") {
          newChr = "%3A";
        } else if (chr === "/") {
          newChr = "%2F";
        }
        newStr += newChr;
      }
      return newStr;
    },
    roundTheNumber(num) {
      let newNum = (Math.round(num * 100) / 100).toFixed(2);
      return newNum;
    },
    checkStatus() {
      if (this.gstrPayId) {
        this.$axios
          .get("Billing/GetIotPayStatus?payId=" + this.gstrPayId)
          .then((response) => {
            if (response.data == "Approved") {
              this.dialogVisible = false;
              this.gstrPayId = "";
              clearInterval(this.gintTimerHandle);
              this.$notify({
                title: "Success",
                message: "Thank you for your payment !",
                type: "success",
                duration: 0,
                onClose: () => {
                  this.$router.go();
                },
              });
              setTimeout(() => {
                this.$router.push("/home");
              }, 5000);
            }
          })
          .catch(() => {
            clearInterval(this.gintTimerHandle);
          });
      }
    },
    checkAllInputAmounts() {
      switch (this.navType) {
        case "postpaid":
          this.allPostpaidMembersData.forEach((el) => {
            let diybalance = Number(el.diyBalance);
            if (el.balance > 0) {
              if (isNaN(diybalance)) {
                this.$notify.error({
                  title: "Error !",
                  message: "Please input a number for your amount !",
                });
                return false;
              }
              if (diybalance > 300) {
                this.$notify.error({
                  title: "Error!",
                  message: "The maximum payment amount is $300!"
                });
                return false;
              }
              if (diybalance < 0.01) {
                this.$notify.error({
                  title: "Error !",
                  message: "The minimum payment amount is 0.01",
                });
                return false;
              }
            }
          });
          return true;
        case "prepaidCAD":
          console.log(this.getTheTotalPrepaidBalanceCAD == 0);

          if (this.getTheTotalPrepaidBalanceCAD == 0) {
            this.$notify.error({
              title: "Error !",
              message: "You must select at least one account to pay!",
            });
            return false;
          } else {
            return true;
          }
        case "prepaidUSD":
          if (this.getTheTotalPrepaidBalanceUSD == 0) {
            this.$notify.error({
              title: "Error !",
              message: "You must select at least one account to pay!",
            });
            return false;
          } else {
            return true;
          }
      }
    },
    getPaymentDetailPostpaid() {
      let details = [];
      this.allPostpaidMembersData.forEach((el) => {
        if (el.diyBalance > 0) {
          details.push({
            AcctOwnerCnum: this.accountOwnerNumber,
            AcctMemberCnum: el.cnum,
            amount: el.diyBalance,
            currency: "CAD",
            paymentMethod: "",
            ex_subtotal: 0,
            ex_sdate: "",
            ex_edate: "",
            hsrate: 0,
            psrate: 0,
          });
        }
      });
      return details;
    },
    getPaymentDetailPrepaidCAD() {
      let details = [];
      this.allPrepaidMembersDataCAD.forEach((el) => {
        if (el.isChecked) {
          details.push({
            AcctOwnerCnum: this.accountOwnerNumber,
            AcctMemberCnum: el.cnum,
            amount: el.totalAmount,
            currency: "CAD",
            paymentMethod: "",
            ex_subtotal: 0,
            ex_sdate: el.newStartDate,
            ex_edate: "",
            hsrate: 0,
            psrate: 0,
          });
        }
      });
      return details;
    },
    getPaymentDetailPrepaidUSD() {
      let details = [];
      this.allPrepaidMembersDataUSD.forEach((el) => {
        if (el.isChecked) {
          details.push({
            AcctOwnerCnum: this.accountOwnerNumber,
            AcctMemberCnum: el.cnum,
            amount: el.totalAmount,
            currency: "USD",
            paymentMethod: "",
            ex_subtotal: 0,
            ex_sdate: el.newStartDate,
            ex_edate: "",
            hsrate: 0,
            psrate: 0,
          });
        }
      });
      return details;
    },
    handlePayment(type) {
      if (this.checkAllInputAmounts()) {
        let currentUrl = window.location.href;
        let homeUrl =
          currentUrl.substring(0, currentUrl.length - 13) +
          (this.$store.state.login.clientType === "postpaid"
            ? "home"
            : "prepaid-home");
        let systag;
        let paymentDetail;
        let totalAmount;
        if (this.navType === "postpaid") {
          systag = "acctarea/multi";
          paymentDetail = this.getPaymentDetailPostpaid();
          totalAmount = this.getTheTotalPostpaidBalance;
        } else if (this.navType === "prepaidCAD") {
          systag = "acctarea/multi/topup";
          paymentDetail = this.getPaymentDetailPrepaidCAD();
          totalAmount = this.getTheTotalPrepaidBalanceCAD;
        } else {
          systag = "acctarea/multi/topup/usd";
          paymentDetail = this.getPaymentDetailPrepaidUSD();
          totalAmount = this.getTheTotalPrepaidBalanceUSD;
        }
        if (this.isMobileOrTablet && type === "alipay_qr") {
          type = "alipay_wap";
        }
        let payload = {
          TotalAmt: totalAmount,
          BillType: type,
          Systag: systag,
          Return_URL: homeUrl,
          Cancel_URL: currentUrl,
          PaymentDetail: paymentDetail,
        };
        this.$axios
          .post("Account/MultiLineBilling", payload)
          .then((response) => {
            if (response.data.substring(0, 6) === "Failed") {
              this.$notify.error({
                title: "Error !",
                message: "Failed to pay your balance !",
              });
            } else {
              let redirectUrl = response.data;
              if (type === "alipay_qr" || type === "wx_native") {
                this.chinesePaymentMethod = type;
                let data = redirectUrl.split("^");
                if (data.length == 2) {
                  this.gstrPayId = data[0];
                  this.qrCodeUrl = data[1];
                  this.gintTimerHandle = setInterval(this.checkStatus, 1000);
                } else {
                  console.log("image wrong format");
                }
                this.dialogVisible = true;
              } else {
                // console.log(redirectUrl);
                redirectUrl === "Total Payment Amount Incorrect"
                  ? this.$notify.error({
                      title: "Error !",
                      message: "Total Payment Amount Incorrect !",
                    })
                  : (location.href = redirectUrl);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify.error({
              title: "Error !",
              message: "Payment Error !",
            });
          });
      }
    },
    getAllPostpaidBalances() {
      this.$axios
        .get(
          "Account/MultiLineAllAccountsBillingInfoByPlanType?acctType=postpaid"
        )
        .then((response) => {
          let data = JSON.parse(response.data);
          data.forEach((el) => {
            this.allPostpaidMembersData.push({
              name: el.FirstName + " " + el.LastName,
              balance: el.Balance,
              diyBalance: el.Balance,
              cnum: el.Cnum,
            });
            if (el.Balance > 0) {
              this.payAllPostpaidBalancesAmount += el.Balance;
            }
          });
          this.payAllPostpaidBalancesAmount = this.payAllPostpaidBalancesAmount.toFixed(
            2
          );
          if (this.allPostpaidMembersData.length > 0) this.navType = "postpaid";
          this.getAllPrepaidAccountInfo();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllPrepaidAccountInfo() {
      this.$axios
        .get(
          "Account/MultiLineAllAccountsBillingInfoByPlanType?acctType=prepaid"
        )
        .then((response) => {
          let data = JSON.parse(response.data);
          data.forEach((el) => {
            let payload = {
              cnum: el.Cnum,
              currbal: el.Balance,
              pfId: el.PfID,
              sdate: el.StartDate,
              edate_curr: el.CurEndDate,
              edate: el.EndDate,
              duration: el.Duration,
              gst: el.GSTRate,
              pst: el.PSTRate,
              pfee: el.PlanFee,
              svcFee: el.ServiceFee,
              currency: el.Currency,
              name: el.FirstName + " " + el.LastName,
              // ui control
              isChecked: true,
              // need calculations
              newStartDate: "",
              newEndDate: "",
              totalAmount: 0,
            };
            // calculat the total amount
            let totalAmount =
              payload.pfee * (1 + payload.gst + payload.pst) +
              payload.currbal +
              payload.svcFee;
            if (totalAmount < 0.01) totalAmount = 0.01;
            totalAmount = (Math.round(totalAmount * 100) / 100).toFixed(2);

            // calculate the new start day = edate + 1 day
            let startDate = new moment.utc(payload.edate);
            startDate = startDate.add(1, 'd');
            let newStartDate = startDate.toISOString().split("T")[0];
            // calculate the new expiry day = edate + duration days
            let endDate = new moment.utc(payload.edate);
            endDate.add(Number(payload.duration), 'd');
            let newEndDate = endDate.toISOString().split("T")[0];
            payload.newStartDate = newStartDate;
            payload.newEndDate = newEndDate;
            payload.totalAmount = totalAmount;
            if (payload.currency === "CAD") {
              this.allPrepaidMembersDataCAD.push(payload);
              this.payAllPrepaidBalancesAmountCAD += Number(totalAmount);
            } else {
              this.allPrepaidMembersDataUSD.push(payload);
              this.payAllPrepaidBalancesAmountUSD += Number(totalAmount);
            }
          });
          this.payAllPrepaidBalancesAmountCAD = this.payAllPrepaidBalancesAmountCAD.toFixed(
            2
          );
          this.payAllPrepaidBalancesAmountUSD = this.payAllPrepaidBalancesAmountUSD.toFixed(
            2
          );
          if (this.allPostpaidMembersData.length === 0) {
            this.allPrepaidMembersDataCAD.length > 0
              ? (this.navType = "prepaidCAD")
              : (this.navType = "prepaidUSD");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.isMobileOrTablet = window.innerWidth <= 1024;
    this.getAllPostpaidBalances();
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.creditCard {
  cursor: pointer;
}

.clientsTypeStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .clientsTypeStyle {
    flex-direction: column;
  }
}
</style>
